/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import type { ComponentType, ReactNode } from 'react';

import { jsx } from '@compiled/react';

import { cssMap, type StrictXCSSProp } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';

const styles = cssMap({
	root: {
		position: 'relative',
	},
	badgeContainer: {
		position: 'absolute',
		insetBlockStart: token('space.negative.050'),
		insetInlineEnd: token('space.negative.025'),
		pointerEvents: 'none',
	},
});

type BadgeContainerProps = {
	/* The id of the badge */
	id: string;

	/* The component to render as the badge */
	badge: ComponentType;

	/**
	 * The children to render inside the container that the badge overlays.
	 */
	children: ReactNode;

	/**
	 * Used to override the accessibility role for the element.
	 */
	role?: 'presentation' | 'listitem';

	xcss?: StrictXCSSProp<'display', never>;
};

/**
 * __Badge container__
 *
 * Adds a badge to the top right corner of the child element.
 * Example of usage is showing the number of notifications in the top navigation bar.
 */
export const BadgeContainer = ({
	children,
	id: badgeId,
	badge: Badge,
	role,
	xcss,
}: BadgeContainerProps) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
	<div css={styles.root} role={role} className={xcss}>
		{children}
		<div css={styles.badgeContainer} id={badgeId} aria-hidden>
			<Badge />
		</div>
	</div>
);
