import React from 'react';

const AppSwitcher = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 6C4 4.89543 4.89543 4 6 4H8.75C9.85457 4 10.75 4.89543 10.75 6V8.75C10.75 9.85457 9.85457 10.75 8.75 10.75H6C4.89543 10.75 4 9.85457 4 8.75V6ZM6 5.5C5.72386 5.5 5.5 5.72386 5.5 6V8.75C5.5 9.02614 5.72386 9.25 6 9.25H8.75C9.02614 9.25 9.25 9.02614 9.25 8.75V6C9.25 5.72386 9.02614 5.5 8.75 5.5H6ZM13.25 6C13.25 4.89543 14.1454 4 15.25 4H18C19.1046 4 20 4.89543 20 6V8.75C20 9.85457 19.1046 10.75 18 10.75H15.25C14.1454 10.75 13.25 9.85457 13.25 8.75V6ZM15.25 5.5C14.9739 5.5 14.75 5.72386 14.75 6V8.75C14.75 9.02614 14.9739 9.25 15.25 9.25H18C18.2761 9.25 18.5 9.02614 18.5 8.75V6C18.5 5.72386 18.2761 5.5 18 5.5H15.25ZM4 15.25C4 14.1454 4.89543 13.25 6 13.25H8.75C9.85457 13.25 10.75 14.1454 10.75 15.25V18C10.75 19.1046 9.85457 20 8.75 20H6C4.89543 20 4 19.1046 4 18V15.25ZM6 14.75C5.72386 14.75 5.5 14.9739 5.5 15.25V18C5.5 18.2761 5.72386 18.5 6 18.5H8.75C9.02614 18.5 9.25 18.2761 9.25 18V15.25C9.25 14.9739 9.02614 14.75 8.75 14.75H6ZM13.25 15.25C13.25 14.1454 14.1454 13.25 15.25 13.25H18C19.1046 13.25 20 14.1454 20 15.25V18C20 19.1046 19.1046 20 18 20H15.25C14.1454 20 13.25 19.1046 13.25 18V15.25ZM15.25 14.75C14.9739 14.75 14.75 14.9739 14.75 15.25V18C14.75 18.2761 14.9739 18.5 15.25 18.5H18C18.2761 18.5 18.5 18.2761 18.5 18V15.25C18.5 14.9739 18.2761 14.75 18 14.75H15.25Z"
			fill="#44546F"
		/>
	</svg>
);

export default AppSwitcher;
